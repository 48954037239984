
import appLogo from '../../assets/images/app_logo.png';
import songsoptok from '../../assets/images/banner/songsoptok.svg';
import banner1 from '../../assets/images/banner1.jpg';
import banner2 from '../../assets/images/banner2.jpg';
import banner3 from '../../assets/images/banner3.jpg';
import banner4 from '../../assets/images/banner4.jpg';
import ebsco from '../../assets/images/digital Library/ebsco.png';
import emerald from '../../assets/images/digital Library/emerald.png';
import jstor from '../../assets/images/digital Library/jstor.png';
import projectMuse from '../../assets/images/digital Library/project muse.png';
import springer from '../../assets/images/digital Library/springer.png';
import iThenticate from "../../assets/images/iThenticate.jpeg";
import profileDemo from '../../assets/images/profile_demo.jpg';
import bookYourSlot from '../../assets/images/quick links/book your slot.png';
import citizensCharter from '../../assets/images/quick links/citizen charter_1.png';
import idCard from '../../assets/images/quick links/ID card.png';
import remote from '../../assets/images/quick links/Remote Access.png';
import thesis2 from '../../assets/images/quick links/thesis.png';
import institutional_repository from '../../assets/images/repository.jpg';

import ashraful from '../../assets/images/citizen_charters/ashraful.jpg';
import muftafiz from '../../assets/images/citizen_charters/mujtafiz.jpg';
import salahuddin from '../../assets/images/citizen_charters/salahuddin.jpg';
const IMAGES = {
    banner1,
    banner2,
    banner3,
    banner4,
    ebsco,
    emerald,
    jstor,
    springer,
    citizensCharter,
    idCard,
    remote,
    thesis2,
    profileDemo,
    bookYourSlot,
    songsoptok,
    projectMuse,
    appLogo,
    iThenticate,
    institutional_repository ,
    muftafiz,
    salahuddin,
    ashraful
};

export default IMAGES;
