import { useState } from "react";
import { FiAlertCircle, FiX } from "react-icons/fi";
import "./ImportantNotice.style.scss";

const notices = [
  {
    id: 1,
    summary: "📢 iThenticate সফটওয়্যার আপগ্রেডের জন্য সাময়িকভাবে বন্ধ থাকবে। বিস্তারিত জানতে ক্লিক করুন।",
    details: "সকলের অবগতির জন্য জানানো যাচ্ছে যে, iThenticate সফটওয়্যারটি আপগ্রেড করে iThenticate Version 2-এ উন্নীত করার কারণে উক্ত সফটওয়্যারটি আগামী ০১ ফেব্রুয়ারী থেকে ০৩ ফেব্রুয়ারী পর্যন্ত সাময়িকভাবে বন্ধ থাকবে। সাময়িক এই অসুবিধার জন্য আমরা আন্তরিকভাবে দুঃখিত।"
  },
];

const ImportantNotice = () => {
  const [selectedNotice, setSelectedNotice] = useState(null);

  return (
    <section className="notice-section">
      <div className="notice-container">
        <h2 className="notice-title">
          <FiAlertCircle className="icon" /> Important Notices
        </h2>
        {notices.map((notice) => (
          <div 
            key={notice.id} 
            className="notice-box" 
            onClick={() => setSelectedNotice(notice.id)}
          >
            <p className="notice-summary">{notice.summary}</p>
          </div>
        ))}
      </div>

      {selectedNotice !== null && (
        <div className="modal-overlay" onClick={() => setSelectedNotice(null)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h3>🔔 Important Notice</h3>
              <FiX className="close-icon" onClick={() => setSelectedNotice(null)} />
            </div>
            <p>{notices.find(notice => notice.id === selectedNotice)?.details}</p>
            <button className="close-button" onClick={() => setSelectedNotice(null)}>
              বন্ধ করুন
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default ImportantNotice;
